<template>
    <div class="transfer-list">
        <transfers-table />
    </div>
</template>

<script>
import TransfersTable from '@/components/transfers/TransfersTable.vue';

export default {
    components: { TransfersTable },
};
</script>