<template>
    <div class="transfers-table">
        <ui-table-skeleton v-if="loading" />
        <template v-else>
            <div class="row g-2 mb-4">
                <div class="col-md-12 col-lg-3">
                    <ui-input
                        id="search"
                        name="search"
                        label="Search transactions"
                        type="text"
                        placeholder="Enter keywords"
                        :debounce="500"
                        v-model:value="filters.q"
                    >
                        <template #prepend>
                            <span class="fas fa-search"></span>
                        </template>
                    </ui-input>
                </div>
                <template v-if="_available_filters">
                    <div class="col-md-auto">
                        <date-range-picker
                            ref="picker"
                            v-model="range"
                            opens="right"
                            time-picker
                            time-picker-24-hours
                            show-dropdowns
                            control-container-class="transfers-table__date-range-picker"
                            :linked-calendars="false"
                            @update="changeDate"
                        >
                            <template #input>
                                <em class="fa fa-fw fa-calendar ml-n1"></em>
                                {{ $dayjs(filters.range.startDate).format('D MMMM YYYY') }} -
                                {{ $dayjs(filters.range.endDate).format('D MMMM YYYY') }}
                                <strong class="caret"></strong>
                            </template>
                        </date-range-picker>
                    </div>
                    <div class="col-md-6 col-lg-auto">
                        <ui-select-multiple
                            id="status"
                            name="status"
                            label="Status"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.status"
                            :options="_available_filters.status.map(status => ({ key: status.label, value: status.value }))"
                        />
                    </div>
                    <div class="col-md-6 col-lg-auto">
                        <ui-select-multiple
                            id="currency"
                            name="currency"
                            label="Currency"
                            class="w-100"
                            option-label="key"
                            option-value="value"
                            v-model:value="filters.currency"
                            :options="_available_filters.currency.map(currency => ({ key: currency.label, value: currency.value }))"
                        />
                    </div>
                </template>
            </div>
            <ui-table class="mb-4" :items="transfers" :fields="fields" :busy="busy">
                <template #cell(id)="data">
                    <ui-link v-if="data.item.transaction" :to="{ name: 'transfers-info', query: { id: data.item.id } }">
                        {{ data.item.transaction.transaction_id }}
                    </ui-link>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
                <template #cell(reference_id)="data">
                    <span v-if="data.item.transaction" class="word-break">
                        {{ data.item.transaction.reference_id }}
                    </span>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
                <template #cell(concept)="data">
                    <span v-if="data.item.concept" class="word-break">
                        {{ data.item.concept }}
                    </span>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
                <template #cell(activity)="data">
                    <activity-info :transaction="data.item" />
                </template>
                <template #cell(company)="data">
                    <span class="text-uppercase">{{ data.item.company }}</span>
                </template>
                <template #cell(receiver)="data">
                    <template v-if="data.item.account">
                        <ui-link weight="medium" :to="{ name: 'accounts-info', query: { id: data.item.account_id } }" target="_blank">
                            <span class="font-weight-600">{{ data.item.account.first_name }} {{ data.item.account.last_name || '' }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.account.email" underline weight="regular" :link="'mailto:' + data.item.account.email">
                                {{ data.item.account.email }}
                            </ui-link>
                            <ui-link v-else-if="data.item.account.phone" underline weight="regular">
                                {{ data.item.account.phone }}
                            </ui-link>
                        </div>
                        <ui-badge v-if="data.item.account.name" variant="success" class="align-self-start badge-large">{{ data.item.account.name }}</ui-badge>
                    </template>
                    <template v-else-if="data.item">
                        <span class="font-weight-600">{{ data.item.beneficiary_name }}</span>
                        <div>
                            <ui-link v-if="data.item.beneficiary_account" weight="regular">
                                {{ data.item.beneficiary_account }}
                            </ui-link>
                        </div>
                    </template>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
                <template #cell(payer)="data">
                    <template v-if="data.item">
                        <ui-link weight="medium" target="_blank">
                            <span class="font-weight-600">{{ data.item.ordering_name }}</span>
                        </ui-link>
                        <div>
                            <ui-link v-if="data.item.ordering_account" underline weight="regular">
                                {{ data.item.ordering_account }}
                            </ui-link>
                        </div>
                    </template>
                    <ui-badge v-else variant="secondary">
                        No information
                    </ui-badge>
                </template>
                <template #cell(amount)="data">
                    <div class="d-flex align-items-center justify-content-end">
                        <span
                            v-if="data.item.custom_keys"
                            v-b-tooltip="'Transaction generated with custom keys'"
                            class="fas fa-key me-2 text-gray-500"
                        ></span>
                        <ui-transaction-amount
                            v-if="data.item.transaction"
                            :amount="data.item.amount"
                            :currency="data.item.currency"
                            :type="data.item.transaction.type"
                        />
                        <ui-transaction-amount
                            v-else
                            :amount="data.item.amount"
                            :currency="data.item.currency"
                        />
                    </div>
                </template>
                <template #cell(fee)="data">
                    <ui-transaction-amount
                    v-if="data.item.transaction"
                    :amount="data.item.transaction.fee"
                    :currency="data.item.currency"
                    type="charge"
                    />
                </template>
                <template #cell(created_at)="data">
                    {{ $dayjs(data.item.created_at).format('L') }} <br />
                    {{ $dayjs(data.item.created_at).format('LTS') }}
                </template>
                <template #cell(status)="data">
                    <ui-status class="fs-13px" :status="data.item.status" />
                </template>
            </ui-table>
            <div class="d-flex justify-content-center">
                <ui-pagination v-model="filters.page" :pages="total_pages" class="mt-3" />
            </div>
        </template>
    </div>
</template>

<script>
import DateRangePicker from 'daterange-picker-vue3';
import 'daterange-picker-vue3/dist/daterange-picker-vue3.css';
import UiTable from '@/components/ui/Table';
import UiTableSkeleton from '@/components/ui/skeleton/TableSkeleton';
import UiTransactionAmount from '@/components/ui/TransactionAmount';
import UiStatus from '@/components/ui/BadgeStatus';
import UiLink from '@/components/ui/Link';
import ActivityInfo from '@/components/transactions/ActivityInfo';
import UiInput from '@/components/ui/Input';
import UiBadge from '@/components/ui/Badge';
import UiPagination from '@/components/ui/Pagination';
import table_mixin from '@/mixins/tables.mixin';
import UiSelectMultiple from '@/components/ui/inputs/SelectMultiple';

export default {
    name: 'TransfersTable',
    components: {
        UiTable,
        UiTableSkeleton,
        DateRangePicker,
        UiTransactionAmount,
        UiStatus,
        UiLink,
        ActivityInfo,
        UiInput,
        UiBadge,
        UiPagination,
        UiSelectMultiple
    },
    mixins: [table_mixin],
    data() {
        const range = {
            startDate: this.$dayjs()
                .subtract(1, 'year')
                .startOf('day')
                .toDate(),
            endDate: this.$dayjs()
                .endOf('day')
                .toDate(),
        };
        return {
            busy: false,
            downloadingReport: false,
            fields: [
                {
                    key: 'id',
                    label: 'Transaction ID'
                },
                {
                    key: 'reference_id',
                    label: 'Reference ID'
                },
                {
                    key: 'concept',
                    label: 'Concept'
                },
                {
                    key: 'company',
                    label: 'Company'
                },
                {
                    key: 'receiver',
                    label: 'Account'
                },
                {
                    key: 'payer',
                    label: 'Customer'
                },
                {
                    key: 'amount',
                    label: 'Amount',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'fee',
                    label: 'Fee',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'created_at',
                    label: 'Date',
                    tdClass: 'text-end',
                    thClass: 'text-end',
                },
                {
                    key: 'status',
                    label: 'Status'
                },
            ],
            filters: {
                q: '',
                page: 1,
                limit: 50,
                currency: null,
                range: { ...range },
                status: null,
            },
            inititialLoading: true,
            loading: false,
            range: { ...range },
            total_pages: null,
            transfers: null,
        };
    },
    watch: {
        filters: {
            immediate: false,
            handler() {
                this.getTransfers();
            },
            deep: true,
        },
    },
    mounted() {
        this._getFilters('transfers');
    },
    methods: {
        downloadReport() {
            this.$refs.downloadReportSnackbar.download({ name: 'transfers', url: this.downloadLink });
            this.downloadingReport = true;
        },
        changeDate(data) {
            this.filters.range = data;
        },
        async getTransfers() {
            try {
                if (this.inititialLoading) {
                    this.loading = true;
                }

                this.busy = true;

                const params = {
                    page: this.filters.page,
                    limit: this.filters.limit,
                    currency: this.filters.currency,
                    status: this.filters.status,
                    'created_at[from]': this.$dayjs(this.filters.range.startDate).toISOString(),
                    'created_at[to]': this.$dayjs(this.filters.range.endDate).toISOString(),
                };

                this._clearFilters(params);

                if (String(this.filters.q).trim() !== '') {
                    params.q = this.filters.q;
                }

                const { data } = await this.$axios.get('/_/transfers', { params });
                this.total_pages = data.pages;
                this.transfers = data.docs;
            } catch (error) {
                this.$toast.error(this.getErrorMessage(error));
            } finally {
                if (this.inititialLoading) {
                    this.loading = false;
                    this.inititialLoading = false
                }
                this.busy = false;
            }
        },
    },
};
</script>

<style lang="scss">
.transfers-table {
    &__date-range-picker {
        align-items: center;
        background-color: $general-white;
        border: 1px solid $ecart-secondary-200;
        border-radius: 8px;
        display: flex;
        gap: 8px;
        height: 40px;
        padding: 4.5px 8px;
        width: 100%;

        .caret {
            margin-left: auto;

            &::before {
                color: $ecart-accent;
                content: '\f078';
                font-size: 16px;
                line-height: 1;
            }
        }
    }

    .vue-daterange-picker {
        width: 100%;
    }
}
</style>